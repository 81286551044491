:root {
    --black1: #060606;
    --black2: #0c0c0e;
    --white-pure: #FFFFFF;
    --white-100: #F4F4F4;
    --white-90: #F4F4F4E6;
    --white-80: #F4F4F4CD;
    --white-70: #F4F4F4B2;
    --white-50: #F4F4F480;
    --white-30: #F4F4F44d;
    --white-20: #F4F4F433;
    --white-10: #F4F4F41a;
    --white-8: #F4F4F414;
    --white-4: #F4F4F40a;
    --black-100: #0A0A0A;
    --black-80: #0A0A0ACD;
    --black-70: #0A0A0AB2;
    --black-50: #0A0A0A80;
    --black-30: #0A0A0A4d;
    --black-20: #0A0A0A33;
    --black-10: #0A0A0A1a;
    --black-8: #0A0A0A14;
    --black-4: #0A0A0A0a;
    --gradient-purple: linear-gradient(to right, #DB43C8, #4B48E5);
    --gradient-orange: linear-gradient(to right, #DB437A, #E5D648);
    --gradient-aqua: linear-gradient(to right, #43B7DB, #4857E5);
    --gradient-hotpink: linear-gradient(12deg, #2a7824, #7fe548);
    --gradient-hotpink-50: linear-gradient(to right, #D843DB50, #E5484850);
    --gradient-candy: linear-gradient(to right, #D843DB, #48E5DC);
    --gradient-candy-10: linear-gradient(to right, #D843DB10, #48E5DC10);
    --gradient-candy-dark: linear-gradient(to right, #971E9A, #16A199);
    --gradient-burn: linear-gradient(45deg, #662b65, #b43676, #ef7d3f, #ebbd70, #fcca97);
    --gradient-burn-50: linear-gradient(45deg, #662b6550, #b4367650, #ef7d3f50, #ebbd7050, #fcca9750)
}

.black1 {
    color: var(--black1)
}

.bg-black1 {
    background-color: var(--black1)
}

.black2 {
    color: var(--black2)
}

.bg-black2 {
    background-color: var(--black2)
}

.pointer {
    cursor: pointer !important;
}

.white-pure {
    color: var(--white-pure)
}

.white-pure-h:hover {
    color: var(--white-pure) !important
}

.bg-white-pure {
    background-color: var(--white-pure)
}

.white-100 {
    color: var(--white-100)
}

.white-100-h:hover {
    color: var(--white-100)
}

.bg-white-100 {
    background-color: var(--white-100)
}

.white-90 {
    color: var(--white-90)
}

.white-90-h:hover {
    color: var(--white-90)
}

.bg-white-90 {
    background-color: var(--white-90)
}

.white-80 {
    color: var(--white-80)
}

.white-80-h:hover {
    color: var(--white-80)
}

.bg-white-80 {
    background-color: var(--white-80)
}

.white-70 {
    color: var(--white-70)
}

.white-70-h:hover {
    color: var(--white-70)
}

.bg-white-70 {
    background-color: var(--white-70)
}

.white-50 {
    color: var(--white-50)
}

.white-50-h:hover {
    color: var(--white-50)
}

.bg-white-50 {
    background-color: var(--white-50)
}

.white-30 {
    color: var(--white-30)
}

.white-30-h:hover {
    color: var(--white-30)
}

.bg-white-30 {
    background-color: var(--white-30)
}

.white-20 {
    color: var(--white-20)
}

.white-20-h:hover {
    color: var(--white-20)
}

.bg-white-20 {
    background-color: var(--white-20)
}

.white-10 {
    color: var(--white-10)
}

.white-10-h:hover {
    color: var(--white-10)
}

.bg-white-10 {
    background-color: var(--white-10)
}

.bg-white-10-h {
    transition: background-color 150ms ease;
}

.bg-white-10-h:hover {
    background-color: var(--white-10)
}

.white-8 {
    color: var(--white-8)
}

.white-8-h:hover {
    color: var(--white-8)
}

.bg-white-8 {
    background-color: var(--white-8)
}

.white-4 {
    color: var(--white-4)
}

.white-4-h:hover {
    color: var(--white-4)
}

.bg-white-4 {
    background-color: var(--white-4)
}

.black-100 {
    color: var(--black-100)
}

.black-100-h:hover {
    color: var(--black-100)
}

.bg-black-100 {
    background-color: var(--black-100)
}

.black-80 {
    color: var(--black-80)
}

.black-80-h:hover {
    color: var(--black-80)
}

.bg-black-80 {
    background-color: var(--black-80)
}

.black-70 {
    color: var(--black-70)
}

.black-70-h:hover {
    color: var(--black-70)
}

.bg-black-70 {
    background-color: var(--black-70)
}

.black-50 {
    color: var(--black-50)
}

.black-50-h:hover {
    color: var(--black-50)
}

.bg-black-50 {
    background-color: var(--black-50)
}

.black-30 {
    color: var(--black-30)
}

.black-30-h:hover {
    color: var(--black-30)
}

.bg-black-30 {
    background-color: var(--black-30)
}

.black-20 {
    color: var(--black-20)
}

.black-20-h:hover {
    color: var(--black-20)
}

.bg-black-20 {
    background-color: var(--black-20)
}

.black-10 {
    color: var(--black-10)
}

.black-10-h:hover {
    color: var(--black-10)
}

.bg-black-10 {
    background-color: var(--black-10)
}

.black-8 {
    color: var(--black-8)
}

.black-8-h:hover {
    color: var(--black-8)
}

.bg-black-8 {
    background-color: var(--black-8)
}

.black-4 {
    color: var(--black-4)
}

.black-4-h:hover {
    color: var(--black-4)
}

.bg-black-4 {
    background-color: var(--black-4)
}

.bg-black-4-h {
    transition: background-color 100ms ease;
}

.bg-black-4-h:hover {
    background-color: var(--black-4)
}

.bg-black-50-h {
    transition: background-color 100ms ease;
}

.bg-black-50-h:hover {
    background-color: var(--black-50)
}

.gradient-purple {
    color: var(--gradient-purple);
}

.bg-gradient-purple {
    background-image: var(--gradient-purple);
}

.gradient-orange {
    color: var(--gradient-orange);
}

.bg-gradient-orange {
    background-image: var(--gradient-orange);
}

.gradient-aqua {
    background-image: var(--gradient-hotpink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-mask {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-aqua-50 {
    background-image: var(--gradient-hotpink-50);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-burn-50 {
    background-image: var(--gradient-burn-50);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-aqua {
    background-image: var(--gradient-aqua);
}

.gradient-hotpink {
    background-image: var(--gradient-hotpink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-hotpink {
    background-image: var(--gradient-hotpink);
}

.gradient-burn {
    background-image: var(--gradient-burn);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-burn {
    background-image: var(--gradient-burn);
    border: none !important
}

.gradient-candy {
    color: var(--gradient-candy);
}

.bg-gradient-candy {
    background-image: var(--gradient-candy);
}

.gradient-candy-dark {
    color: var(--gradient-candy-dark);
}

.bg-gradient-candy-dark {
    background-image: var(--gradient-candy-dark);
}

.load-more-break {
    border-bottom: 1px solid var(--black-4);
}

/*.artist-venue-splash-img {*/
/*    background-image: url("../assets/img/artist-splash.png");*/
/*    background-size: cover;*/
/*}*/

.transparent {
    color: transparent !important;
}

