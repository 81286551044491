p {
    font-size: 20px;
}

.body1 {
    font-size: 16px;
}

.body1-bold {
    font-size: 16px;
    font-weight: 800;
}

.body2 {
    font-size: 14px;
}

.body2-bold {
    font-size: 14px;
    font-weight: 800;
}

.body3 {
    font-size: 11px;
}

.body3-bold {
    font-size: 11px;
    font-weight: 800;
}

.headline {
    font-size: 81px;
    line-height: 0;
}

.headline2 {
    font-size: 81px;
}

.headline-sm {
    font-size: 52px;
    line-height: 50px;
}

.title {
    font-size: 48px;
}

.title2 {
    font-size: 38px;
}

.title3 {
    font-size: 30px;
    font-weight: 800;
}

.title4 {
    font-size: 24px;
    font-weight: 800;
}

.o8 {
    opacity: .8;
}

.o65 {
    opacity: .65;
}

.o5 {
    opacity: .5;
}

.o2 {
    opacity: .2;
}

.o1 {
    opacity: .1;
}

.o1-h {
    transition: 150ms ease;
}

.o1-h:hover {
    opacity: 1;
}
